import { Link } from 'gatsby';
import React from 'react';
import { Column, Container, Row } from '../../../components/Grid';
import Icon from '../../../components/Icon';
import Image from '../../../components/Image';
import Layout from '../../../components/Layout';
import Action from '../../../components/Resource/Action';
import Section from '../../../components/Section';
import Video from '../../../components/Video';

const CapstoneMasterclassChallengesPage = () => {
  return (
    <Layout title="Capstone Project" className="capstone-masterclass">
      {/* HEADER */}
      <Section className="capstone-project__header-section">
        <Container fullWidth>
          <div className="capstone-project__header-breadcrumb">
            <Link
              className="capstone-project__header-breadcrumb-link"
              to="/classroom-instruction"
            >
              Introduction
            </Link>
            <Icon
              name="caratright"
              marginLeft
              marginRight
              className="capstone-project__header-breadcrumb-link"
            />
            <span>Capstone Course</span>
          </div>
        </Container>
        <div className="capstone-project__header-container">
          <p className="capstone-project__header-text">Capstone Year</p>
          <p style={{ textAlign: 'center' }}>
            The Futurelab+ capstone is a culminating year designed to support
            students’ entrepreneurial drive, creativity, and innovative spirit.
            Students will explore and research a topic about which they are
            passionate, applying the knowledge they gained from Genentech’s
            Futurelab+ syllabi.
          </p>
        </div>
      </Section>

      {/* OVERVIEW */}
      <Section className="overview pb-1">
        <Container>
          <Row className="pb-1">
            <Column size={12} className="pr-2">
              <h3 className="pb-1 mb-0">Capstone Masterclass Series</h3>
              <p className="pb-1 pt-0">
                Meet capstone educators to learn more about the next generation
                of diverse science and healthcare professionals.
              </p>
              <p>
                Capstone Projects are brought to life through the Masterclass
                videos. Gain insight into a real classroom experience and watch
                as the Capstone project takes shape.
              </p>
              <p>
                The Futurelab+ suite of Masterclass resources are designed to
                guide and support students through completion of the year-long
                Capstone project. This collection includes project exemplars, a
                journal to track student progress and establish project
                milestones and expectations, and a capstone rubric to support
                the effective implementation of their experience.
              </p>
              <p>
                The Capstone Project Management Guide will inform students about
                opportunities to earn onsite hours and complete independent
                research during their capstone year. In addition, it will
                highlight how schools can offer biotech CTE pathways and address
                challenges like the availability of local biotech resources, CTE
                certification, equipment, and other outside capstone support
                including ideas for onsite experiences related to biotech and
                medical facilities.
              </p>
            </Column>
          </Row>
        </Container>
      </Section>

      {/* VIDEOS */}
      <Section
        className="videos educators-action__video-hero pt-1 pb-0"
        id="videos"
      >
        <Container fullWidth className="educators-action__video-hero-container">
          <Row className="educators-action__video-hero-videos">
            {/* MAIN VIDEO */}
            <Column size={8}>
              <Video
                className="hero-img-video__video"
                guidSrc="16146b93-f11c-4a23-b2f5-e12557a50207"
                autoPlay={true}
              />
            </Column>
            {/* VIDEO THUMBNAILS */}
            <Column size={4}>
              <div className="educators-action__video-hero-thumbs">
                {/* Video 1 */}
                <div className="educators-action__video-hero-thumbs-item">
                  <span className="educators-action__video-hero-thumb">
                    <Link to="/classroom-instruction/capstone-masterclass-series/project-overview/#videos">
                      <Image
                        filename="thmb-capstone-masterclass-video-1.png"
                        className="thumbs-border"
                      />
                    </Link>
                  </span>
                  <span className="educators-action__video-hero-text">
                    <Link to="/classroom-instruction/capstone-masterclass-series/project-overview/#videos">
                      <h3>CAPSTONE PROJECT OVERVIEW</h3>
                      <span className="text-13">3:50 min</span>
                      <p className="text-13  truncate-5">
                        This Masterclass Video introduces Pedro Delgado, who
                        shares how the Capstone Project is a powerful
                        culminating research project for students. In the
                        Capstone Project students present their year-long
                        project to an industry professional. Utilize this video
                        with the Masterclass Educator Guide.
                      </p>
                    </Link>
                  </span>
                </div>
                {/* Video 2 */}
                <div className="educators-action__video-hero-thumbs-item active">
                  <span className="educators-action__video-hero-thumb">
                    <Link to="/classroom-instruction/capstone-masterclass-series/challenges/#videos">
                      <Image
                        filename="thmb-capstone-masterclass-video-2.png"
                        className="thumbs-border"
                      />
                    </Link>
                  </span>
                  <span className="educators-action__video-hero-text">
                    <Link to="/classroom-instruction/capstone-masterclass-series/challenges/#videos">
                      <h3>CAPSTONE CHALLENGES</h3>
                      <span className="text-13">4:51 min</span>
                      <p className="text-13  truncate-5">
                        Educator Pedro Delgado shares the challenges that arise
                        in implementing Capstone Projects and offers solutions
                        to help address the challenges. Once a teacher builds
                        momentum with the Capstone Project, students can excel!
                      </p>
                    </Link>
                  </span>
                </div>
                {/* Video 3 */}
                <div className="educators-action__video-hero-thumbs-item">
                  <span className="educators-action__video-hero-thumb">
                    <Link to="/classroom-instruction/capstone-masterclass-series/connecting-with-industry-professionals/#videos">
                      <Image
                        filename="thmb-capstone-masterclass-video-3.png"
                        className="thumbs-border"
                      />
                    </Link>
                  </span>
                  <span className="educators-action__video-hero-text">
                    <Link to="/classroom-instruction/capstone-masterclass-series/connecting-with-industry-professionals/#videos">
                      <h3>CONNECTING WITH INDUSTRY PROFESSIONALS</h3>
                      <span className="text-13">4:20 min</span>
                      <p className="text-13  truncate-5">
                        Follow along with educator Pedro Delgado as he shares
                        how to connect with industry professionals and the
                        benefits of connecting with industry professionals in
                        enhancing the Capstone Project.
                      </p>
                    </Link>
                  </span>
                </div>
              </div>
            </Column>
          </Row>
        </Container>
      </Section>

      {/* VIDEO COPY */}
      <Section className="video-copy pb-1 pt-0.5">
        <Container>
          <Row className="pb-1">
            <Column size={8} className="pr-2">
              <h3>Capstone Projects Brought To Life</h3>
              <h4>OVERVIEW</h4>
              <p className="educators-action__getting-started-desc">
                Masterclass videos will highlight featured CTE educators like
                Pedro Delgado describing their experiences with running a
                capstone year and managing onsite internship experiences. A
                Computer Science teacher at Young Women’s STEAM Preparatory
                Academy in El Paso, Texas, Pedro discusses his experience
                guiding his students through the Futurelab+ capstone year.
              </p>
              <p>
                These Masterclass videos will provide an essential inside look
                at what both teachers and students can expect from the capstone
                year and describe students can earn a CTE Pathway Endorsement on
                their high school transcript and, depending on the state, may
                earn college credits while in high school, receive industry
                certifications, and participate in internships and work-based
                learning employment.
              </p>
              <h4>REFLECT</h4>
              <p>
                Having previously done a capstone project with his students,
                Pedro has learned what works and what doesn't. Reflect upon his
                experiences to plan your own capstone year.
              </p>
              <h4>LOOK FORWARD</h4>
              <p>
                Looking forward, what strategies from these videos will you
                incorporate into your capstone year? Where do you see potential
                areas for improving upon what you've just seen?
              </p>
            </Column>
            <Column size={4}>
              <div className="educators-action__educator-guide mt-2">
                <div className="educators-action__educator-guide-heading">
                  <h4>Educator Guide</h4>
                </div>
                <div className="educators-action__educator-guide-body">
                  <h4>Educators in Action</h4>
                  <h3 className="educators-action__educator-guide-content-heading">
                    Educator Guide
                  </h3>
                  <span className="educators-action__educator-guide-duration">
                    <Icon name="clock" />
                    10-15 minutes
                  </span>
                  <p>
                    A Capstone Year is an empowering year of study that requires
                    facilitation strategies to keep students on track and ensure
                    they have the resources they need to complete their project.
                    Use this Educator Guide with the Masterclass videos to
                    support students in their journey.
                  </p>
                  <Action
                    type="download"
                    label="Educator Guide"
                    path="/pdfs/capstone-project/genentech-masterclass-edguide-capstone.pdf"
                    format=".PDF,"
                    size="722 KB"
                  />
                </div>
              </div>
            </Column>
          </Row>
        </Container>
      </Section>

      {/* FEATURED */}
      <Section className="featured pt-1 pb-0 mb-3">
        <Container fullWidth>
          {/* TITLE */}
          <Row className="pb-1">
            <Column size={8}>
              <h4 className="educators-action__above-header">
                Educators in Action
              </h4>
              <h3 className="educators-action__getting-started-header mt-0 mb-0.5">
                Featured Educator
              </h3>
              <p className="educators-action__getting-started-desc">
                Meet a teacher who is bringing biotech to life in student's
                everyday world.
              </p>
            </Column>
          </Row>
          <Row className="pb-1">
            <Column size={3}>
              <div className="educators-action__profile-thumbs">
                <Image filename="thmb-educators-profile-delgado@2x.png" />
              </div>
            </Column>
            <Column size={5}>
              <h3 className="educators-action__featured-educators-name mb-0">
                Pedro Delgado <span>(he/him)</span>
              </h3>
              <h4 className="educators-action__featured-educators-subject mt-0 mb-0.5">
                Computer Science and Science
              </h4>
              <h4 className="educators-action__featured-educators-school mt-0 mb-0.5">
                Young Women's STEAM and Preparatory Academy, El Paso, Texas
              </h4>
              <p className="educators-action__featured-educators-desc">
                Pedro is a passionate advocate for using computational thinking
                and project based learning framework to engage students from
                diverse backgrounds in real-world learning experiences.
              </p>
              <h4 className="educators-action__featured-educators-videos mt-0 mb-0.5">
                Featured Videos:
              </h4>
              <ul className="educators-action__featured-educators-links">
                <li>
                  <Link to="/classroom-instruction/capstone-masterclass-series/project-overview/">
                    Project Overview
                  </Link>
                </li>
                <li>
                  <Link to="/classroom-instruction/capstone-masterclass-series/challenges/">
                    Capstone Challenges
                  </Link>
                </li>
                <li>
                  <Link to="/classroom-instruction/capstone-masterclass-series/connecting-with-industry-professionals/">
                    Connecting with Industry Professionals
                  </Link>
                </li>
              </ul>
            </Column>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default CapstoneMasterclassChallengesPage;
